import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { generateJwtHeaders } from "../../helper/tokenUtils";

const useDiplomas = () => {
  const navigate = useNavigate();

  return useQuery(
    ["allDiplomas"],
    async () => {
      const idToken = sessionStorage.getItem("oauth_token");
      if (!idToken) {
        throw new Error("Unauthorized");
      }

      const baseUri = process.env.REACT_APP_USE_ID_CONNECT_ALL_RESOURCES_URI;
      const uri = `${baseUri}`;

      const headers = await generateJwtHeaders(baseUri, "GET");
      const response = await fetch(uri, { headers });

      if (response.ok) {
        const { search_result } = await response.json();
        return search_result;
      }

      if (response.status === 401 || response.status === 403) {
        navigate("/");
        throw new Error(response.statusText);
      }
    },
    {
      retry: 0
    }
  );
};

export default useDiplomas;
