import { formatDate } from "../helper/stringUtils";

const Diploma = ({ diplomaQueryResult }) => {
  if (diplomaQueryResult.status === "loading") {
    return (
      <div className="bg-white p-8 rounded-md break-all">
        <div className="flex-1 mb-4 flex items-center gap-2 p-2 rounded-sm border border-gray-500">
          <DocumentIcon />
          <div className="h-4 w-full bg-gray-200 rounded-md"></div>
        </div>

        <div className="mb-1">uitreikende instelling</div>
        <div className="px-4 py-3 bg-gray-200 rounded-md text-lg mb-4">
          <div className="h-2 w-[80%] bg-white rounded-md mb-2"></div>
          <div className="h-2 w-[70%] bg-white rounded-md"></div>
        </div>

        <div className="mb-1">toekenningsdatum</div>
        <div className="px-4 py-3 bg-gray-200 rounded-md text-lg mb-4">
          <div className="h-2 w-[80%] bg-white rounded-md mb-2"></div>
          <div className="h-2 w-[70%] bg-white rounded-md"></div>
        </div>
      </div>
    );
  }

  if (diplomaQueryResult.status === "error") {
    return null;
  }

  const diploma = diplomaQueryResult.data;

  return (
    <div className="bg-white p-8 rounded-md break-all">
      <div
        className={`flex-1 mb-4 flex items-center gap-2 p-2 rounded-sm border border-gray-500 ${
          diploma.vcCheck ? "bg-white" : "bg-red-100"
        }`}
      >
        <DocumentIcon />
        <div className="flex flex-col">
          <div className="text-xl text-randstad-light-blue">{diploma.title}</div>
          {!diploma.vcCheck && <div className="text-slate-800">fout bij verificatie</div>}
        </div>
      </div>

      <div className="mb-1">uitreikende instelling</div>
      <div className="px-4 py-3 bg-gray-200 rounded-md text-lg mb-4 text-slate-800 overflow-scroll whitespace-nowrap no-scrollbar">
        {diploma.issuer}
      </div>

      <div className="mb-1">toekenningsdatum</div>
      <div className="px-4 py-3 bg-gray-200 rounded-md text-lg mb-4 text-slate-800">{formatDate(new Date(diploma.date))}</div>
    </div>
  );
};

export default Diploma;

const DocumentIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
      />
    </svg>
  );
};
