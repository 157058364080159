export const base64RemovePadding = (str) => {
  return str.replace(/={1,2}$/, "");
};

export const stringToUint8Array = (contents) => {
  const encoded = btoa(encodeURIComponent(contents));
  return base64ToUint8Array(encoded);
};

export const uint8ArrayToString = (unsignedArray) => {
  const base64string = btoa(String.fromCharCode(...unsignedArray));
  return base64string.replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
};

const base64ToUint8Array = (base64Contents) => {
  base64Contents = base64Contents.replace(/-/g, "+").replace(/_/g, "/").replace(/\s/g, "");
  const content = atob(base64Contents);
  return new Uint8Array(content.split("").map((c) => c.charCodeAt(0)));
};

export const formatDate = (date) => {
  if (date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month}/${year}`;
  }

  return "";
};
