import useAuthorize from "../hooks/mutations/useAuthorize";

const Login = () => {
  // const { mutateAsync: login, isLoading, isError } = useAccessToken();
  const { mutateAsync: authorize, isLoading, isError } = useAuthorize();

  return (
    <>
      <h1 className="mb-4">stap 1 van 2</h1>

      <h2 className="text-3xl text-randstad-light-blue mb-4">diploma('s) ophalen en delen</h2>

      <p className="mb-2">
        Wij vragen u om in het bijzijn van uw consulent uw diploma op te vragen uit uw persoonlijke Datakluis die wordt beheerd door het
        Vlaams Datanutsbedrijf als aparte verwerkingsverantwoordelijke, zodat wij uw geschiktheid kunnen beoordelen voor de functies
        waarvoor wij rekruteren. Randstad bewaart geen kopie van de gegevens die via uw Datakluis zichtbaar zijn, en bewaart alleen een
        logging omtrent het feit dat het diploma is opgeroepen. Deze logging wordt na 1 maand weer verwijderd. Indien u uw diploma niet
        wenst op te vragen via uw persoonlijke Datakluis, verzoeken wij u dit met uw consulent te bespreken. Dan brengen wij uw inschrijving
        bij Randstad op een andere manier in orde.
      </p>

      <p>
        Verder blijft onze{" "}
        <a href="https://www.randstad.be/nl/disclaimer/werknemers/" target="_blank" className="underline" rel="noreferrer">
          Privacy Statement
        </a>{" "}
        volledig van toepassing op onze relatie. Hierin vind je o.a. het volgende :
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          Randstad Belgium NV (hierna Randstad) is de verwerkingsverantwoordelijke voor de verwerking van jouw persoonsgegevens. U kan de
          functionaris voor gegevensbescherming (DPO) van Randstad bereiken via{" "}
          <a href="mailto:privacy@randstadgroup.be" className="underline">
            privacy@randstadgroup.be
          </a>{" "}
          ;
        </li>
        <li>
          Meer informatie over de wijze waarop je uw rechten, waaronder het recht op inzage en het recht om vergeten te worden, kan
          uitoefenen.
        </li>
        <li>
          Uw persoonsgegevens worden verwerkt op basis van uw vrije toestemming. U hebt steeds het recht deze toestemming in te trekken;
        </li>
        <li>
          Wij wijzen u daarnaast op het feit dat u steeds het recht hebt om klacht in te dienen bij de{" "}
          <a href="https://www.gegevensbeschermingsautoriteit.be/burger/startpagina" className="underline" target="_blank" rel="noreferrer">
            toezichthoudende autoriteit
          </a>
          .
        </li>
      </ul>

      {isError && <p className="text-red-500 mb-4">er ging iets mis</p>}

      <button
        className={`py-4 ${
          isLoading ? "bg-gray-400" : "bg-randstad-light-blue"
        } text-white rounded-md text-lg w-full md:w-[300px] font-display inline-flex items-center justify-center gap-2`}
        onClick={authorize}
        disabled={isLoading}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 20H4.214V18H2V6H14.414L17.414 3H22V19H24V1H16.586L13.586 4H0V20ZM5.5835 22.0237L7.0255 23.4087C8.6205 21.7467 10.7665 20.8317 13.0675 20.8317C15.3045 20.8317 17.4065 21.7027 18.9885 23.2847L20.4025 21.8707C18.4425 19.9107 15.8385 18.8317 13.0675 18.8317C10.2165 18.8317 7.5585 19.9657 5.5835 22.0237ZM13.0001 9.9868C11.6151 9.9868 10.4881 11.1138 10.4881 12.4988C10.4881 13.8838 11.6151 15.0108 13.0001 15.0108C14.3851 15.0108 15.5121 13.8838 15.5121 12.4988C15.5121 11.1138 14.3851 9.9868 13.0001 9.9868M13.0001 17.0108C10.5121 17.0108 8.4881 14.9868 8.4881 12.4988C8.4881 10.0108 10.5121 7.9868 13.0001 7.9868C15.4881 7.9868 17.5121 10.0108 17.5121 12.4988C17.5121 14.9868 15.4881 17.0108 13.0001 17.0108"
            fill="#EEE"
          />
        </svg>
        <span>Delen via mijn burgerprofiel</span>
      </button>
    </>
  );
};

export default Login;
