import { useMutation } from "@tanstack/react-query";
import pkceChallenge from "pkce-challenge";

const useAuthorize = () => {
  return useMutation(async () => {
    const { codeChallenge, codeVerifier } = generateCodeChallengeAndVerifier();
    const state = generateRandomCharacter(25);

    sessionStorage.setItem("oauth_state", state);
    sessionStorage.setItem("oauth_code_verifier", codeVerifier);

    const webIdAsClientId = encodeURIComponent(process.env.REACT_APP_WEBID);
    const personalDataTypeUrl = encodeURIComponent(process.env.REACT_APP_AR_TEMPLATE_URL);
    window.location.href = `${process.env.REACT_APP_OAUTH_AUTHORIZE_ENDPOINT}?response_type=code&client_id=${webIdAsClientId}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&state=${state}&code_challenge=${codeChallenge}&code_challenge_method=S256&personal_data_type_url=${personalDataTypeUrl}`;
  });
};

export default useAuthorize;

const generateCodeChallengeAndVerifier = () => {
  const { code_challenge: codeChallenge, code_verifier: codeVerifier } = pkceChallenge();
  return { codeChallenge, codeVerifier };
};

const generateRandomCharacter = (length) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
  const charactersLength = characters.length;
  let randomCharacter = "";
  for (let i = 0; i < length; i++) {
    randomCharacter += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return randomCharacter;
};
