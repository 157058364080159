import "./index.css";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CookiesProvider } from "react-cookie";
import Header from "./components/Header";
import Redirect from "./pages/Redirect";
import Diplomas from "./pages/Diplomas";
import Login from "./pages/Login";

function App() {
  const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });

  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <Router>
          <Header />

          <div className="px-4 mt-8 md:px-32 lg:px-60">
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="redirect" element={<Redirect />} />
              <Route path="diplomas" element={<Diplomas />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </Router>
      </CookiesProvider>
    </QueryClientProvider>
  );
}

export default App;
