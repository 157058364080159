import { useQueries } from "@tanstack/react-query";
import { generateJwtHeaders } from "../../helper/tokenUtils";

const useAllDiplomaDetails = (allDiplomas = []) => {
  return useQueries({
    queries: allDiplomas.map(toDiplomaQuery)
  });
};

export default useAllDiplomaDetails;

const toDiplomaQuery = (diploma) => {
  return {
    queryKey: ["diploma", diploma.resource_uri],
    queryFn: () => fetchDiploma(diploma.resource_uri),
    retry: 0,
    enabled: !!diploma.resource_uri
  };
};

const fetchDiploma = async (uri) => {
  const baseUri = `${process.env.REACT_APP_USE_ID_CONNECT_RESOURCE_BASE_URI}/${encodeURIComponent(uri)}`;

  const headers = await generateJwtHeaders(baseUri, "GET");
  const response = await fetch(`${baseUri}?vc-check=true`, { headers });

  if (response.ok) {
    const responseBody = await response.json();
    const title = responseBody["Leercredential.display"]["http://purl.org/dc/terms/title"]["@value"];
    const issuer =
      responseBody["credentialSubject"]["Persoon.claimt"][0]["Leerclaim.toegekendDoor"]["Toekenningsproces.uitgevoerdDoor"][0][
        "Organisatie.voorkeursnaam"
      ]["@value"];
    const date = new Date(
      responseBody["credentialSubject"]["Persoon.claimt"][0]["Leerclaim.toegekendDoor"]["Toekenningsproces.toekenningsdatum"]
    );
    const vcCheck = response.headers.get("x-useid-vc-verification");

    return {
      title,
      issuer,
      date,
      vcCheck
    };
  }

  if (response.status === 401 || response.status === 403) {
    throw new Error(response.statusText);
  }

  throw new Error(await response.text());
};
