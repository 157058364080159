import useDiplomas from "../hooks/queries/useDiplomas";
import { useNavigate } from "react-router-dom";
import useAllDiplomaDetails from "../hooks/queries/useAllDiplomaDetails";
import Diploma from "../components/Diploma";

const Diplomas = () => {
  const navigate = useNavigate();
  const { data: allDiplomas, isLoading: isLoadingDiplomaUris, isError } = useDiplomas();
  const queryResults = useAllDiplomaDetails(allDiplomas);

  if (isLoadingDiplomaUris) {
    return <div className="text-xl">aan het laden ...</div>;
  }

  if (isError) {
    return <div className="text-xl">er ging iets fout ...</div>;
  }

  if (allDiplomas.length === 0) {
    return (
      <>
        <div className="mb-4">stap 2 van 2</div>
        <div className="text-3xl text-randstad-light-blue mb-4">diploma('s) van kandidaat</div>
        <div className="mb-4">er werden geen diploma’s gevonden.</div>
        <div className="mb-4">kan het zijn dat de kandidaat geen diploma’s gedeeld heeft of zijn/haar toestemming heeft ingetrokken?</div>
        <div className="mb-4">
          ziet u een fout of ontbrekende gegevens, neem contact op met{" "}
          <a href="mailto:led@ond.vlaanderen.be" className="text-randstad-light-blue">
            led@ond.vlaanderen.be
          </a>
          .
        </div>

        <button
          className={`py-4 bg-randstad-light-blue text-white rounded-md text-lg w-full md:w-[300px] font-display inline-flex items-center justify-center gap-2`}
          onClick={() => navigate("/")}
        >
          opnieuw beginnen
        </button>
      </>
    );
  }

  return (
    <>
      <div className="mb-4">stap 2 van 2</div>

      <div className="text-3xl text-randstad-light-blue mb-4">diploma('s) van kandidaat</div>

      <div className="mb-4">het volgende werd gedeeld door de kandidaat.</div>

      {queryResults.map((result) => result.status).includes("error") && (
        <div className="mb-4">* één of meerdere diploma's konden niet correct opgehaald worden.</div>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {queryResults.map((diplomaQueryResult, index) => {
          return <Diploma key={index} diplomaQueryResult={diplomaQueryResult} />;
        })}
      </div>
    </>
  );
};

export default Diplomas;
