import RandstadLogo from "../assets/images/RandstadLogo";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  return (
    <div
      className="w-full h-[200px] bg-randstad-dark-blue flex flex-col items-start justify-center px-4 gap-6 md:px-32 lg:px-60 hover:cursor-pointer"
      onClick={() => navigate("/")}
    >
      <RandstadLogo />
      <div className="w-full mt h-[.5px] bg-white"></div>
    </div>
  );
};

export default Header;
