import { v4 } from "uuid";
import { base64RemovePadding, stringToUint8Array, uint8ArrayToString } from "./stringUtils";
import localforage from "localforage";

export const generateDPoPToken = async (htm, htu) => {
  const keyPair = await getKeyPair();
  const encodedJwtHeader = await generateEncodedJwtHeader(keyPair.publicKey);
  const encodedJwtClaims = await generateEncodedJwtClaims(htm, htu);
  const encodedSignature = await generateEncodedJwtSignature(keyPair, encodedJwtHeader, encodedJwtClaims);

  return `${encodedJwtHeader}.${encodedJwtClaims}.${encodedSignature}`;
};

const getKeyPair = async () => {
  const keys = await localforage.keys();
  if (keys.includes("keyPair")) {
    return localforage.getItem("keyPair");
  }

  const keyPair = await window.crypto.subtle.generateKey(
    {
      name: "ECDSA",
      namedCurve: "P-256"
    },
    true,
    ["sign", "verify"]
  );
  await localforage.setItem("keyPair", keyPair);
  return keyPair;
};

const generateEncodedJwtHeader = async (publicKey) => {
  const fullJwk = await window.crypto.subtle.exportKey("jwk", publicKey);

  const jwtHeader = {
    alg: "ES256",
    typ: "dpop+jwt",
    jwk: { crv: fullJwk.crv, kty: fullJwk.kty, x: fullJwk.x, y: fullJwk.y }
  };
  return base64RemovePadding(btoa(JSON.stringify(jwtHeader)));
};

const generateEncodedJwtClaims = async (htm, htu) => {
  const claims = {
    htm: htm,
    htu: htu,
    iat: new Date().getTime() / 1000,
    jti: v4()
  };
  return base64RemovePadding(btoa(JSON.stringify(claims)));
};

const generateEncodedJwtSignature = async (keyPair, encodedJwtHeader, encodedJwtClaims) => {
  const headerAndPayload = `${encodedJwtHeader}.${encodedJwtClaims}`;
  const messageAsUint8Array = stringToUint8Array(headerAndPayload);

  const signature = await window.crypto.subtle.sign(
    {
      name: "ECDSA",
      hash: "SHA-256"
    },
    keyPair.privateKey,
    messageAsUint8Array
  );

  return uint8ArrayToString(new Uint8Array(signature));
};

export const generateJwtHeaders = async (uri, htm) => {
  const accessToken = sessionStorage.getItem("oauth_token");
  const dPopToken = await generateDPoPToken(htm, uri);

  const headers = new Headers();
  headers.append("Authorization", `DPoP ${accessToken}`);
  headers.append("DPoP", `${dPopToken}`);
  return headers;
};
