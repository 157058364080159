import useTokens from "../hooks/queries/useTokens";
import { useNavigate, useSearchParams } from "react-router-dom";

const Redirect = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const error = searchParams.get("error");
  const code = searchParams.get("code");

  const { isLoading, isError } = useTokens({ code });

  if (error) {
    return (
      <div>
        <div className="text-3xl text-randstad-light-blue mb-4">oeps</div>
        {error === "cancelled" && <div className="mb-4">het proces om diploma's te delen werd onderbroken door de kandidaat.</div>}
        {error === "access_denied" && <div className="mb-4">de kandidaat heeft beslist om zijn of haar diploma's niet te delen.</div>}
        {error !== "cancelled" && error !== "access_denied" && (
          <>
            <div className="mb-4">er is een onbekende fout opgetreden.</div>
            <div className="mb-4">technische code: {error}</div>
          </>
        )}

        <button
          className={`py-4 bg-randstad-light-blue text-white rounded-md text-lg w-full md:w-[300px] font-display inline-flex items-center justify-center gap-2`}
          onClick={() => navigate("/")}
        >
          opnieuw beginnen
        </button>
      </div>
    );
  }

  if (isLoading) {
    return <div>aan het laden ...</div>;
  }

  if (isError) {
    return <div>er is een interne fout opgetreden ...</div>;
  }

  return <div></div>;
};

export default Redirect;
