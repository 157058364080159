import { useQuery } from "@tanstack/react-query";
import { generateJwtHeaders } from "../../helper/tokenUtils";
import { useNavigate } from "react-router-dom";

const useTokens = ({ code }) => {
  const navigate = useNavigate();
  return useQuery(
    ["handleRedirect", code],
    async () => {
      const headers = await generateJwtHeaders(process.env.REACT_APP_OAUTH_TOKEN_ENDPOINT, "POST");
      headers.append("Content-Type", "application/x-www-form-urlencoded");

      const response = await fetch(process.env.REACT_APP_OAUTH_TOKEN_ENDPOINT, {
        method: "POST",
        headers: headers,
        body: new URLSearchParams({
          grant_type: "authorization_code",
          client_id: process.env.REACT_APP_WEBID,
          code: code,
          code_verifier: sessionStorage.getItem("oauth_code_verifier"),
          redirect_uri: process.env.REACT_APP_REDIRECT_URI
        })
      });

      if (response.ok) {
        const { id_token: idToken } = await response.json();
        return idToken;
      }

      throw new Error("OAuth state values do not match");
    },
    {
      retry: 0,
      enabled: !!code,
      onSuccess: (idToken) => {
        sessionStorage.setItem("oauth_token", idToken);
        navigate("/diplomas");
      }
    }
  );
};

export default useTokens;
